<template>
    <ODataLookup :data-object="dsActivityTypesLookup" :whereClause="`'${IdPath}' LIKE PublishedToIdPath`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly/>
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="150"></OColumn>
        <OColumn field="Description" width="300"></OColumn>  
    </ODataLookup>
</template>

<script setup>
    import { ODataLookup } from 'o365-datalookup';
 
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsActivityTypesLookup = $getDataObjectById("dsComplete_ActivityTypesLookup");
</script>